import React from "react";
import Helmet from 'react-helmet';
import { Container, Row, Col, Form, Button, DropdownButton, Dropdown } from 'react-bootstrap';
import { Link } from "gatsby";

/**
 * Layouts
 */
import Layout from '@layouts/default';

/**
 * Components
 */
import Intro from '@components/intro/intro';
import About from '@components/about/about';
import BookVideo from '@components/book-video/book-video';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import EntryHeading from '@components/entry-heading/entry-heading';
import LoadMorePagination from '@components/load-more-pagination/load-more-pagination';
import { graphql } from 'gatsby'
import get from 'lodash/get'
import SEO from "../components/seo"
import Newsletter from '@components/book-video/newsletter';

class NewsCategoryTemplate extends React.Component {

  render() {

    const Page = get(this, 'props.data.strapiNewsEvents')
    const News = get(this, 'props.data.allStrapiNewsEvents.edges')
    const Category = get(this, 'props.data.allStrapiNewsCategories.edges')
    const Sub_Category = get(this, 'props.data.allStrapiNewsSubCategories.edges')
    const ActiveCategory = get(this, 'props.data.strapiNewsCategories')


    return (
      <Layout>
        <Helmet 
          bodyAttributes={{
            class: 'static news-landing templates-news-category-template-js'
          }}
        />
        <SEO title={ActiveCategory.Meta_Title} description={ActiveCategory.Meta_Description} />
        <div className="page-body">


      <Container>
        <EntryHeading Title={ActiveCategory.Title_H1} Sub_Title={ActiveCategory.Sub_Heading} Content_Alignment="Left"/>
      </Container>

      <Container>

      <div className="news-filters">
        <div className="news-filter-desktop">
          <Link to="../latest-news" className="btn btn-outline-primary cta">All Posts</Link>
          {Category.map(({node}, key) => {
            return <>
              {ActiveCategory.Category_Name == node.Category_Name &&
              <>
              {node.news_events.length != 0 &&
              <Link to={`../${node.URL}`} className="btn btn-outline-primary cta active">{node.Category_Name}</Link>
              }
              </>
              }
              {ActiveCategory.Category_Name != node.Category_Name &&
              <>
              {node.news_events.length != 0 &&
              <Link to={`../${node.URL}`} className="btn btn-outline-primary cta">{node.Category_Name}</Link>
              }
              </>
              }
            </>
          })}
        </div>


        <div className="news-filters news-filter-mobile">
          <DropdownButton id="dropdown-basic-button" title={ActiveCategory.Category_Name}>
            <Dropdown.Item selected><Link to="../latest-news">All Posts</Link></Dropdown.Item>
            {Category.map(({node}, key) => {
              return <>
              {node.news_events.length != 0 &&
              <>
              {ActiveCategory.Category_Name != node.Category_Name &&
                <Dropdown.Item selected><Link to={`../${node.URL}`}>{node.Category_Name}</Link></Dropdown.Item>
              }
              </>
              }
              </>
            })}
          </DropdownButton>
        </div>



        <div className="news-sub-category">
          <span className="main">All {ActiveCategory.Category_Name}</span>  
          {Sub_Category.map(({node}, key) => {
          return <span className="sub-category-list-span">
          {node.news_events.length != 0 &&
          <Link to={`${node.URL}`}>{node.Category_Name}</Link>
          }
          </span>
        })}
        </div>
      </div>


      {News.length == 0 &&
        <p>Sorry, there is no posts yet</p>
      }


      <LoadMorePagination content={News} path="/about-us/property-news" LoadMore="Load More" Level="1"/>


      </Container>

      <Newsletter /> 

      <Breadcrumbs Parent_Label="About" Parent_URL="about/holroyds" Page_1={ActiveCategory.Category_Name}/>
      </div>
      </Layout>
    )
  }
}

export default NewsCategoryTemplate;


export const pageQuery = graphql`
  query NewsCategoryTemplateSlug($slug: String!) {

  allStrapiNewsEvents(filter: {Publish: {eq: true}, news_category: {id: {eq: $slug}}}, sort: {fields: Date, order: DESC}) {
    edges {
      node {
        id
        Title
        URL
        Tile_Image {
          internal {
            description
          }
          publicURL
        }
        Date(formatString: "Do MMMM, YYYY")
        news_category {
          URL
        }
        Sub_Category {
          Category_Name
        }
        Upload_Video {
          publicURL
        }
        Embed_Video_URL
      }
    }
  }

  allStrapiNewsCategories(filter: {Publish: {eq: true}}, sort: {fields: Sort, order: ASC}) {
    edges {
      node {
        Category_Name
        URL
        news_events {
          Title
        }
      }
    }
  }

  strapiNewsCategories(strapiId: {eq: $slug}) {
    Category_Name
    Meta_Description
    Meta_Title
    Title_H1
    Sub_Heading
  }

  allStrapiNewsSubCategories(filter: {Publish: {eq: true}, news_events: {elemMatch: {news_category: {eq: $slug}}}}) {
    edges {
      node {
        Category_Name
        URL
        news_events {
          Title
        }
      }
    }
  }


  }
`